import React from "react";
import clsx from "clsx";

import { htmlSafe, stripParagraphTags } from "modules/utils";
import { HeroCarouselProps } from "..";
import CtaButton from "components/Cta/Button";
import { CtaProps } from "components/Cta/types";
import ArrowSVG from "modules/theme/icons/chevrons/wide-chevron-down.svg";
import SvgDivider from "modules/theme/ims/hero-carousel-divider.svg";
import PreloadImage from "modules/lazyload/PreloadImage";
import { ButtonStyle } from "components/Button/constants";

const HeroCarouselSkeleton: React.FunctionComponent<HeroCarouselProps> = ({ arrow, hero_carousel_module }) => {
  const heroCarousel = hero_carousel_module;
  return (
    <div className={`slick-slider slick-initialized`}>
      <div className="slick-list">
        <div className="slick-track">
          <div className="slick-slide">
            <div>
              <div style={{ width: "100%", display: "flex" }}>
                {heroCarousel.map((item: any, index) => {
                  const heroImage = item.hero_carousel_items.hero_carousel_image?.url;
                  const title = item.hero_carousel_items.hero_carousel_title;
                  const safeTitle = htmlSafe(stripParagraphTags(title));
                  const text = item.hero_carousel_items.hero_carousel_subtext;
                  const slogan = item.hero_carousel_items.slogan_title;
                  const sloganText = (
                    <>
                      <span>Powering</span>
                      <span>Best</span>
                      <span>Practice</span>
                    </>
                  );
                  const className = clsx(
                    "carousel",
                    item.hero_carousel_items.image_overlay && "hasOverlay",
                    slogan && "hasSlogan"
                  );
                  const displayTitle = index === 0 ? <h1>{safeTitle}</h1> : <h2>{safeTitle}</h2>;
                  const displaySlogan = index === 0 ? <h1>{sloganText}</h1> : <h2>{sloganText}</h2>;
                  return (
                    <div
                      className={className}
                      key={index}
                      style={{ width: "100vw", visibility: index === 0 ? "visible" : "hidden" }}
                    >
                      <PreloadImage
                        className="image"
                        alt={htmlSafe(stripParagraphTags(item.hero_carousel_items.hero_carousel_title))}
                        desktop={{ height: 800, width: 1920, aspectRatio: "12:5" }}
                        mobile={{ height: 400, width: 360, aspectRatio: "9:10" }}
                        src={heroImage}
                      />
                      <div className="container small">
                        <SvgDivider className="heroCarouselDivider" />
                        <div className="carouselContentWrapper">
                          {slogan ? (
                            displaySlogan
                          ) : (
                            <>
                              {title && displayTitle}
                              {text && <p>{text}</p>}
                            </>
                          )}
                          {arrow && <ArrowSVG />}
                          {(item.hero_carousel_items.cta.page_link.length > 0 ||
                            item.hero_carousel_items.cta.custom_url) && (
                            <div className="carouselLink">
                              <CtaButton
                                {...(item.hero_carousel_items.cta as CtaProps)}
                                defaultColour={ButtonStyle.BRAND_BERRY}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {heroCarousel.length > 1 && (
        <div className="slick-dots">
          <ul className="container dots-flex">
            {heroCarousel.map((item: any, index) => {
              const className = clsx(index === 0 && "active", "dot");
              return (
                <li>
                  <button className={className}>{index + 1}</button>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default HeroCarouselSkeleton;
