import { OrganizationsItemType } from "modules/api/endpoints/organizations";

type useValidateFiltersProps = {
  activeTraining: string[];
  activeCountry: string[];
  activeRegion: string[];
  name: string;
  setIsLoading: (value: React.SetStateAction<boolean>) => void;
  setTotalRecords: (value: React.SetStateAction<number | null>) => void;
  setATOEntries: (value: React.SetStateAction<OrganizationsItemType[] | null>) => void;
  setErrorMessage: (value: React.SetStateAction<string | null>) => void;
  emptyMessage: string;
  setSkeleton: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useValidateFilters = ({
  activeTraining,
  activeCountry,
  activeRegion,
  name,
  setIsLoading,
  setTotalRecords,
  setATOEntries,
  setErrorMessage,
  emptyMessage,
  setSkeleton,
}: useValidateFiltersProps) => {
  const validate = () => {
    setIsLoading(true);
    if (!name && activeTraining.length === 0 && activeCountry.length === 0 && activeRegion.length === 0) {
      // No filter options set
      setTotalRecords(0);
      setATOEntries(null);
      setErrorMessage(emptyMessage);
      setTimeout(() => {
        setSkeleton(false);
        setIsLoading(false);
      });
      return false;
    }
    return true;
  };

  return { validate };
};
