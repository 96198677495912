import React, { useRef } from "react";
import clsx from "clsx";

import { ButtonTarget } from "components/Button/constants";
import { addProtocol } from "modules/utils";
import { defaultValues, model, schema } from "components/ATO/model";
import { ModalType } from "modules/modals/constants";
import { OrganizationsItemType } from "modules/api/endpoints/organizations";
import { TrainingType } from "../types";
import { useDataLayer } from "modules/gtm";
import * as Button from "components/Button";
import * as Hyperlink from "components/Hyperlink";
import AtoQuestions from "components/ATO/Questions";
import useModalAction from "modules/modals/hooks/useModalAction";

import * as styles from "./styles.module.scss";

type ATOEntryProps = {
  entry: OrganizationsItemType;
  training: TrainingType[];
};

const Entry: React.FC<ATOEntryProps> = ({ entry, training }) => {
  const { modalShow, modalHide } = useModalAction();
  const modal = useRef<string | null>(null);
  const address = [
    entry.address_line_1,
    entry.address_line_2,
    entry.address_state,
    entry.address_postcode,
    entry.address_country,
  ].filter((el) => {
    return el != "";
  });
  const handleClick = () => {
    if (!modal.current) {
      modal.current = modalShow({
        onCancel: () => {
          modal.current && modalHide(modal.current);
          modal.current = null;
        },
        renderBodyContent: () => (
          <AtoQuestions
            companyName={entry.name}
            defaultValues={defaultValues}
            formType="ATO"
            id={entry.uid}
            modal={modal}
            model={model}
            schema={schema}
          />
        ),
        renderHeaderContent: () => (
          <h2>
            Contact <span>{entry.name}</span>
          </h2>
        ),
        type: ModalType.FORM,
      });
    }
  };

  return (
    <div className={styles.atoEntry}>
      <header>
        <p>{entry.name}</p>
      </header>
      <div>
        <p>Training offered</p>
        <ul>
          {entry.bestPractices.map((i: any, key: any) => {
            const bestPracticesColour = training.find((item: any) => item.title === i)?.colour;
            const className = clsx(styles[`${bestPracticesColour}`]);
            return (
              <li key={key} className={className}>
                <span>{i}</span>
              </li>
            );
          })}
        </ul>
      </div>
      <footer>
        <p>
          {address.map((item, index) => {
            return (
              <React.Fragment key={index}>
                {item}
                {index !== address.length - 1 ? ", " : ""}
              </React.Fragment>
            );
          })}
        </p>
        <ul>
          <li>
            {entry.website && (
              <Hyperlink.CustomLink
                label="Website"
                href={entry.website}
                onClick={() => {
                  useDataLayer({
                    action: "ATO Website Click",
                    category: "Find a Training Provider",
                    label: addProtocol(entry.website),
                  });
                }}
                target={ButtonTarget.BLANK}
              />
            )}
          </li>
          <li>
            <Button.Generic
              label="Contact"
              onClick={() => {
                useDataLayer({
                  action: "Contact Button Click",
                  category: "Find a Training Provider",
                  label: `${entry.name}`,
                });
                return handleClick();
              }}
            />
          </li>
        </ul>
      </footer>
    </div>
  );
};

export default Entry;
