import { useRef, useState } from "react";

import { API, api } from "modules/api";
import { errorModal } from "./ErrorModal";
import { FormSubmissionPostResponse } from "modules/api/endpoints/form-submissions";
import { NewsletterPopupCopyType } from "modules/newsletter/types";
import { RecaptchaPayload } from "components/Questions";
import { successModal } from "./SuccessModal";
import logger from "modules/logger";
import useModalAction from "modules/modals/hooks/useModalAction";

export const useSendFormData = ({
  formType,
  copy,
  dataLayer,
  modal,
  organisationId,
}: {
  formType: string;
  copy?: NewsletterPopupCopyType;
  dataLayer?: () => void;
  modal?: React.MutableRefObject<string | null>;
  organisationId?: string;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { modalShow, modalHide } = useModalAction();
  const errorModalRef = useRef<string | null>(null);
  const succesModalRef = useRef<string | null>(null);

  async function handleSubmit<T>(data: RecaptchaPayload<T>) {
    if ("activeElement" in document) (<HTMLElement>document.activeElement).blur();
    setIsSubmitting(true);
    if (data.recaptchaResponse !== null) {
      const request = {
        formType,
        id: organisationId,
        "g-recaptcha-response": data.recaptchaResponse,
        ...data.formData,
      };
      try {
        return await api(API.POST_FORM_SUBMISSIONS(request)).then((res: FormSubmissionPostResponse) => {
          successModal({ copy, modal, modalHide, modalShow, succesModalRef });
          dataLayer && dataLayer();
          logger.debug(formType + " Form Submission - Successful:", res.data);
          return res.data;
        });
      } catch (error: any) {
        errorModal({ error, errorModalRef, modal, modalHide, modalShow });
        logger.error(formType + " Form Submission - Error:", error);
      } finally {
        setIsSubmitting(false);
      }
    }
  }
  return { handleSubmit, isSubmitting } as const;
};
