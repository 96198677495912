import { DropdownType } from "components/DropdownSelect/constants";
import { CountriesItemType, RegionsItemType } from "modules/api/endpoints/countries";
import { ATOQueryTypes } from "pages/{ContentstackAtoListPage.url}";
import { useEffect } from "react";
import { TrainingType } from "../types";

type useDropdownProps = {
  activeCountry: string[];
  data: ATOQueryTypes;
  setActiveTraining: (value: React.SetStateAction<string[]>) => void;
  setTraining: (value: React.SetStateAction<TrainingType[]>) => void;
  setActiveCountry: (value: React.SetStateAction<string[]>) => void;
  setCountryDropdownOpen: (value: React.SetStateAction<boolean>) => void;
  setCountry: (value: React.SetStateAction<CountriesItemType[]>) => void;
  country: CountriesItemType[];
  countryButtonRef: React.RefObject<HTMLButtonElement>;
  countryDropdownOpen: boolean;
  countryDropdownRef: React.RefObject<HTMLDivElement>;
  countryEntries: CountriesItemType[] | null;
  setActiveRegion: (value: React.SetStateAction<string[]>) => void;
  setRegionDropdownOpen: (value: React.SetStateAction<boolean>) => void;
  setRegion: (value: React.SetStateAction<RegionsItemType[] | undefined>) => void;
  region: RegionsItemType[] | undefined;
  regionButtonRef: React.RefObject<HTMLButtonElement>;
  regionDropdownOpen: boolean;
  regionDropdownRef: React.RefObject<HTMLDivElement>;
  trainerSearch: any;
};

export const useDropdown = ({
  activeCountry,
  country,
  data,
  setActiveTraining,
  setTraining,
  setActiveCountry,
  setCountryDropdownOpen,
  setCountry,
  countryEntries,
  countryButtonRef,
  countryDropdownOpen,
  countryDropdownRef,
  setActiveRegion,
  setRegionDropdownOpen,
  setRegion,
  region,
  regionButtonRef,
  regionDropdownOpen,
  regionDropdownRef,
  trainerSearch,
}: useDropdownProps) => {
  useEffect(() => {
    if (!data) return;

    setTraining(() => {
      return data.allContentstackResourceHubBestPractices.edges
        .filter((item: any) => item.node.ato)
        .map((item: any) => item.node)
        .sort((a: any, b: any) => a.title.localeCompare(b.title));
    });
  }, [data]);

  useEffect(() => {
    if (!countryEntries) return;
    setCountry(() => {
      return countryEntries
        .map((item) => {
          item.title = item.name;
          return item;
        })
        .sort((a, b) => {
          return a.title && b.title ? a.title.localeCompare(b.title) : 0;
        });
    });
  }, [countryEntries]);

  useEffect(() => {
    const allRegions = [{ region: "All Regions", value: "All Regions", label: "All Regions", title: "All Regions" }];
    setRegion(() => {
      const regions = country?.find((i) => i.uid === activeCountry[0])?.regions;
      if (regions === undefined) {
        return undefined;
      }
      return regions && regions.length > 0
        ? allRegions.concat(
            countryEntries
              ?.find((i) => i.uid === activeCountry[0])
              ?.regions?.map((item) => {
                item.title = item.region;
                return item;
              })
              .sort((a, b) => {
                return a.title.localeCompare(b.title);
              }) as any
          )
        : allRegions;
    });
  }, [activeCountry, country]);

  useEffect(() => {
    if (!trainerSearch && !country && !activeCountry && !region) return;
    if (trainerSearch) {
      setActiveRegion((activeIds) => {
        return getActiveIds(activeIds, trainerSearch.region ? trainerSearch.region : "All Regions");
      });
    }
  }, [trainerSearch, country, activeCountry, region]);

  const getActiveIds = (arr: string[], id: string) => {
    if (!id) return [];
    if (arr.includes(id)) {
      return arr.filter((activeId) => activeId !== id);
    }
    return [...arr, id];
  };

  const handleActiveFilteredItems = async (id: string, type: DropdownType) => {
    if (type === DropdownType.BEST_PRACTICES) {
      setActiveTraining((activeIds) => {
        return getActiveIds(activeIds, id);
      });
    }
    if (type === DropdownType.COUNTRY) {
      setActiveCountry((activeIds) => {
        return getActiveIds(activeIds, id);
      });
      setActiveRegion([]);
    }
    if (type === DropdownType.REGION) {
      setActiveRegion((activeIds) => {
        return getActiveIds(activeIds, id);
      });
    }
  };

  const handleCountryDropdown = () => {
    if (countryDropdownOpen) {
      setCountryDropdownOpen(false);
    } else {
      setCountryDropdownOpen(true);
      setRegionDropdownOpen(false);
    }
  };

  const handleRegionDropdown = () => {
    if (regionDropdownOpen) {
      setRegionDropdownOpen(false);
    } else {
      setRegionDropdownOpen(true);
      setCountryDropdownOpen(false);
    }
  };

  useEffect(() => {
    const closeFilterOnClickOutside = (event: any) => {
      if (
        countryButtonRef.current &&
        !countryButtonRef.current.contains(event.target) &&
        countryDropdownRef.current &&
        !countryDropdownRef.current.contains(event.target)
      ) {
        setCountryDropdownOpen(false);
      }
      if (
        regionButtonRef.current &&
        !regionButtonRef.current.contains(event.target) &&
        regionDropdownRef.current &&
        !regionDropdownRef.current.contains(event.target)
      ) {
        setRegionDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", closeFilterOnClickOutside);
    return () => {
      document.removeEventListener("mousedown", closeFilterOnClickOutside);
    };
  }, [countryButtonRef, countryDropdownRef, regionButtonRef, regionDropdownRef]);

  return { handleCountryDropdown, handleRegionDropdown, handleActiveFilteredItems };
};
