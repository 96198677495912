import React from "react";
import clsx from "clsx";

import DividerSVG from "modules/theme/artwork/divider-grey-with-logo.svg";
import { ImageHorizontalPosition } from "modules/images/constants";
import Breadcrumbs from "modules/breadcrumbs";
import LazyloadImage from "modules/lazyload/Image";
import PreloadImage from "modules/lazyload/PreloadImage";

import * as styles from "./styles.module.scss";

export type HeroCategoryBannerProps = {
  case_study_background_colour?: string;
  case_study_company_logo?: any;
  case_study_product_logo?: any;
  crumbs: any;
  hero_image: {
    url: string;
  };
  hero_title: string;
  image_horizontal_position?: ImageHorizontalPosition;
  title: string;
  type: HeroCategory | string;
};

type HeroLogoProps = {
  className?: string;
  logo?: any;
  alt_text?: string;
};
type HeroImageProps = {
  image?: any;
  title: string;
};

export enum HeroCategory {
  PRACTICE = "Practice",
  CASE_STUDY = "Case Study",
  REPORT = "Report",
  WHITE_PAPER = "White Paper",
}

export const HeroLogo: React.FunctionComponent<HeroLogoProps> = ({ alt_text, className, logo }) => {
  const image = logo[0]?.logo?.url;
  const alt = alt_text ? alt_text : logo[0]?.logo?.title;
  return image ? <LazyloadImage alt={alt} className={className} icon src={image} tagName="img" /> : null;
};

export const HeroImage: React.FunctionComponent<HeroImageProps> = ({ image, title }) => {
  return (
    <div className={clsx(styles.image)}>
      <DividerSVG className={styles.flip} />
      <DividerSVG />
      <div className={styles.strip}></div>
      <PreloadImage
        alt={title}
        desktop={{ height: 320, width: 960, aspectRatio: "3:1" }}
        mobile={{ height: 400, width: 360, aspectRatio: "9:10" }}
        src={image}
      />
    </div>
  );
};

const CategoryBanner: React.FunctionComponent<HeroCategoryBannerProps> = ({
  case_study_background_colour,
  case_study_company_logo,
  case_study_product_logo,
  crumbs,
  hero_image,
  hero_title,
  image_horizontal_position = ImageHorizontalPosition.CENTRE,
  title,
  type,
}) => {
  const classNames = clsx(
    styles.heroCategoryBanner,
    styles[`${case_study_background_colour?.replace(/\s/g, "")}`],
    image_horizontal_position && styles[`Horizontal${image_horizontal_position}`],
    type === HeroCategory.CASE_STUDY ? styles.CaseStudy : styles.Brand,
    case_study_company_logo.length === 0 && styles.noCompanyLogo
  );
  return (
    <div className={classNames}>
      <div>
        <Breadcrumbs crumbs={crumbs} currentPageTitle={title} />
        <div className="container small">
          <div className={styles.titleContainer}>
            <h1>{hero_title}</h1>
            <HeroLogo className={styles.companyLogo} logo={case_study_company_logo} />
          </div>
          <div className={clsx(styles.categoryContainer, styles.desktop)}>
            <p>{type}</p>
            <HeroLogo className={styles.productLogo} logo={case_study_product_logo} />
          </div>
        </div>
        <span className={styles.dividerMobile}></span>
        <HeroImage image={hero_image?.url} title={title} />
      </div>
      <div className={clsx(styles.categoryContainer, styles.mobile)}>
        <div className="container small">
          <HeroLogo className={styles.companyLogo} logo={case_study_company_logo} />
          <div>
            <p>{type}</p>
            <HeroLogo className={styles.productLogo} logo={case_study_product_logo} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryBanner;
