type useInputChangesProps = {
  setName: (value: React.SetStateAction<string>) => void;
};

export const useInputChanges = ({ setName }: useInputChangesProps) => {
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  return { handleNameChange };
};
