import React from "react";

type RecaptchaMessageProps = {};

const RecaptchaMessage: React.FC<RecaptchaMessageProps> = () => {
  return (
    <small>
      This site is protected by reCAPTCHA and the Google{" "}
      <a href="https://policies.google.com/privacy" onMouseDown={(e) => e.preventDefault()} target="_blank">
        {" "}
        Privacy Policy
      </a>{" "}
      and{" "}
      <a href="https://policies.google.com/terms" onMouseDown={(e) => e.preventDefault()} target="_blank">
        {" "}
        Terms of Service
      </a>{" "}
      apply.
    </small>
  );
};

export default RecaptchaMessage;
