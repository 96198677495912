type useParamsProps = {
  activeTraining: string[];
  activeCountry: string[];
  activeRegion: string[];
  name: string;
  offset: number;
  records: number;
  type?: string;
};

export const useParams = ({ activeTraining, activeCountry, activeRegion, name, records, offset }: useParamsProps) => {
  return {
    name: name ? name : null,
    bestPractices: !!activeTraining.length ? activeTraining.map((activeItem) => activeItem).join(",") : null,
    countryId: !!activeCountry.length ? activeCountry.map((activeItem) => activeItem).join(",") : null,
    numberPerPage: records,
    offset: offset,
    region:
      !!activeRegion.length && activeRegion[0] !== "All Regions"
        ? activeRegion.map((activeItem) => activeItem).join(",")
        : null,
    type: "ato",
  };
};
