import React from "react";

import * as gridStyles from "../Grid/styles.module.scss";
import * as entryStyles from "../Entry/styles.module.scss";
import * as styles from "./styles.module.scss";
import clsx from "clsx";

type ATOSkeletonProps = {};

const Skeleton: React.FC<ATOSkeletonProps> = () => {
  const className = clsx(styles.atoSkeleton, entryStyles.atoEntry);
  return (
    <div className={gridStyles.atoGrid}>
      <div className="small container">
        <ul className={gridStyles.list}>
          <li>
            <div className={className}>
              <header>
                <p className={styles.shimmer}></p>
              </header>
              <div>
                <p className={styles.shimmer}></p>
                <ul>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                </ul>
              </div>
              <footer>
                <p className={styles.shimmer}> </p>
                <ul>
                  <li className={styles.shimmer}></li>
                  <li className={styles.shimmer}></li>
                </ul>
              </footer>
            </div>
          </li>

          <li>
            <div className={className}>
              <header>
                <p className={styles.shimmer}></p>
              </header>
              <div>
                <p className={styles.shimmer}></p>
                <ul>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                </ul>
              </div>
              <footer>
                <p className={styles.shimmer}> </p>
                <ul>
                  <li className={styles.shimmer}></li>
                  <li className={styles.shimmer}></li>
                </ul>
              </footer>
            </div>
          </li>

          <li>
            <div className={className}>
              <header>
                <p className={styles.shimmer}></p>
              </header>
              <div>
                <p className={styles.shimmer}></p>
                <ul>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                </ul>
              </div>
              <footer>
                <p className={styles.shimmer}> </p>
                <ul>
                  <li className={styles.shimmer}></li>
                  <li className={styles.shimmer}></li>
                </ul>
              </footer>
            </div>
          </li>

          <li>
            <div className={className}>
              <header>
                <p className={styles.shimmer}></p>
              </header>
              <div>
                <p className={styles.shimmer}></p>
                <ul>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                </ul>
              </div>
              <footer>
                <p className={styles.shimmer}> </p>
                <ul>
                  <li className={styles.shimmer}></li>
                  <li className={styles.shimmer}></li>
                </ul>
              </footer>
            </div>
          </li>

          <li>
            <div className={className}>
              <header>
                <p className={styles.shimmer}></p>
              </header>
              <div>
                <p className={styles.shimmer}></p>
                <ul>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                </ul>
              </div>
              <footer>
                <p className={styles.shimmer}> </p>
                <ul>
                  <li className={styles.shimmer}></li>
                  <li className={styles.shimmer}></li>
                </ul>
              </footer>
            </div>
          </li>

          <li>
            <div className={className}>
              <header>
                <p className={styles.shimmer}></p>
              </header>
              <div>
                <p className={styles.shimmer}></p>
                <ul>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                  <li className={styles.shimmer}>
                    <span></span>
                  </li>
                </ul>
              </div>
              <footer>
                <p className={styles.shimmer}> </p>
                <ul>
                  <li className={styles.shimmer}></li>
                  <li className={styles.shimmer}></li>
                </ul>
              </footer>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Skeleton;
