import { QuestionType } from "components/Question/types";
import yup from "modules/validation";

export const schema = yup.object().shape({
  name: yup.string().required().name().max(255, "Name must be at most 255 characters."),
  email: yup.string().required().email().max(255, "Email must be at most 255 characters."),
  contactNumber: yup.string().required().telephone().max(20, "Contact number must be at most 20 characters."),
  enquiry: yup.string().required().max(500, "Enquiry must be at most 500 characters."),
  consent: yup.boolean().oneOf([true], "You must accept the terms and conditions."),
});

export type Model = {
  name: string;
  email: string;
  contactNumber: string;
  enquiry: string;
  consent: boolean;
};

export const defaultValues: Model = {
  name: "",
  email: "",
  contactNumber: "",
  enquiry: "",
  consent: false,
};

const name: QuestionType<Model> = {
  name: "name",
};

const email: QuestionType<Model> = {
  name: "email",
};

const contactNumber: QuestionType<Model> = {
  name: "contactNumber",
};

const enquiry: QuestionType<Model> = {
  name: "enquiry",
};

const consent: QuestionType<Model> = {
  name: "consent",
};

export const model = { name, email, contactNumber, enquiry, consent };
