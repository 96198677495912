import { CountriesItemType } from "modules/api/endpoints/countries";
import { useDataLayer } from "modules/gtm";

type useGtmTriggerProps = {
  activeCountry: string[];
  activeRegion: string[];
  activeTraining: string[];
  countryEntries: CountriesItemType[] | null;
  name: string;
};

export const useGtmTrigger = ({
  activeCountry,
  activeRegion,
  activeTraining,
  countryEntries,
  name,
}: useGtmTriggerProps) => {
  const gtmCountry = countryEntries?.find((i) => i.uid === activeCountry.toString())?.name;
  const cleanName = name ? `${name}` : null;
  const cleanActiveTraining =
    activeTraining.length > 0 ? `${activeTraining.filter((i) => i !== null).join(", ")}` : null;
  const cleanActiveCountry = gtmCountry ? gtmCountry : null;
  const cleanActiveRegion = activeRegion.length > 0 ? `${activeRegion}` : null;
  const gtmParams = [cleanName, cleanActiveTraining, cleanActiveCountry, cleanActiveRegion].filter((i) => i !== null);

  return useDataLayer({
    action: "Search",
    category: "Find a Training Provider",
    label: gtmParams.length === 1 ? gtmParams.join("") : gtmParams.join(", "),
  });
};
