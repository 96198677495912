import React from "react";

import { Modal } from "modules/modals/hooks/useModalAction";
import { ModalType } from "modules/modals/constants";

export const errorModal = ({
  error,
  modal,
  modalHide,
  modalShow,
  errorModalRef,
}: {
  errorModalRef: React.MutableRefObject<string | null>;
  modalHide: (id: string) => void;
  modalShow: (modal: Modal) => string;
  error?: any;
  modal?: React.MutableRefObject<string | null>;
}) => {
  if (!errorModalRef.current) {
    errorModalRef.current = modalShow({
      onClose: () => {
        !!errorModalRef.current && modalHide(errorModalRef.current);
        errorModalRef.current = null;
        if (modal) {
          modal.current && modalHide(modal.current);
          modal.current = null;
        }
      },
      title: `Error: ${error?.response?.status}`,
      text: "There was an error trying to send your message. Please try again later.",
      type: ModalType.INFO,
    });
  }
};
