import React from "react";

import { Modal } from "modules/modals/hooks/useModalAction";
import { ModalType } from "modules/modals/constants";
import { NewsletterPopupCopyType } from "modules/newsletter/types";

export const successModal = ({
  copy,
  modal,
  succesModalRef,
  modalShow,
  modalHide,
}: {
  modalHide: (id: string) => void;
  modalShow: (modal: Modal) => string;
  succesModalRef: React.MutableRefObject<string | null>;
  copy?: NewsletterPopupCopyType;
  modal?: React.MutableRefObject<string | null>;
}) => {
  if (!succesModalRef.current) {
    succesModalRef.current = modalShow({
      onClose: () => {
        !!succesModalRef.current && modalHide(succesModalRef.current);
        succesModalRef.current = null;
        if (modal) {
          modal.current && modalHide(modal.current);
          modal.current = null;
        }
      },
      title: copy?.heading || `Thank you for your enquiry`,
      text: copy?.text || "Your submission has been sent.",
      type: ModalType.INFO,
    });
  }
};
