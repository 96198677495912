import { API, api } from "modules/api";
import { CountriesGetResponse, CountriesItemType } from "modules/api/endpoints/countries";

type useFetchCountriesDataProps = {
  setCountryEntries: (value: React.SetStateAction<CountriesItemType[] | null>) => void;
};

export const useFetchCountriesData = async ({ setCountryEntries }: useFetchCountriesDataProps) => {
  // COUNTRIES
  try {
    const countriesResponse = await api(API.GET_COUNTRIES()).then((res: CountriesGetResponse) => res.data);
    if (countriesResponse.countries.length === 0) {
      // No countries found
      return;
    }
    // Successful
    setCountryEntries(countriesResponse.countries);
  } catch (error) {
    // Error
  }
};
