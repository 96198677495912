import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { graphql } from "gatsby";
import { useWindow } from "modules/utils";
import { useFetchATOData } from "components/ATO/hooks/fetchAtoData";
import { useFetchCountriesData } from "components/ATO/hooks/fetchCountriesData";
import { useResetFilter } from "components/ATO/hooks/resetFilters";
import { useInputChanges } from "components/ATO/hooks/inputChanges";
import { useDropdown } from "components/ATO/hooks/dropdown";
import { useParams } from "components/ATO/hooks/params";
import { OrganizationsItemType } from "modules/api/endpoints/organizations";
import { CountriesItemType, RegionsItemType } from "modules/api/endpoints/countries";
import { ATOFilterPreviewProps } from "components/ATO/Filter/types";
import { useValidateFilters } from "components/ATO/hooks/validateFilters";
import { defaultValues, model, schema } from "components/ATO/model";
import { HeroBannerProps, FilterConfigurationProps, TrainingType } from "components/ATO/types";
import { useNewsletterPopup } from "components/Newsletter/hooks/newsletterPopup";
import { DefaultMetaType, MetaType } from "modules/meta/types";
import { useGtmTrigger } from "components/ATO/hooks/gtmTrigger";
import { NewsletterPopupType } from "modules/newsletter/types";

import * as ATO from "components/ATO";

export type ATOQueryTypes = {
  contentstackNewsletterPopup: NewsletterPopupType;
  contentstackGlobalMeta: DefaultMetaType;
  contentstackAtoListPage: {
    meta: MetaType;
    newsletter: {
      show_popup_page: string;
    };
    hero_banner: HeroBannerProps;
    filterConfiguration: FilterConfigurationProps;
  };
  allContentstackResourceHubBestPractices: {
    edges: {
      node: {
        colour: string;
        id: string;
        title: string;
      };
    }[];
  };
};

type ATOPageProps = { data: ATOQueryTypes; location: any };

const ATOPage: React.FC<ATOPageProps> = ({ data, location }) => {
  /* Newsletter Popup */
  const newsletter = data?.contentstackNewsletterPopup;
  const page = data?.contentstackAtoListPage;
  /* Mini Find A Trainer Search */
  const trainerSearch = useWindow() && window.history.state?.findATrainerSearch;
  /* Ajax Data */
  const [ATOEntries, setATOEntries] = useState<OrganizationsItemType[] | null>([]);
  const [countryEntries, setCountryEntries] = useState<CountriesItemType[] | null>([]);
  /* Input Filters */
  const [name, setName] = useState("");
  const nameRef = useRef<HTMLInputElement>(null);
  /* Dropdown Filter */
  const [training, setTraining] = useState<TrainingType[]>([]);
  const [activeTraining, setActiveTraining] = useState<string[]>(
    !!trainerSearch?.bestPractice ? [].concat(trainerSearch?.bestPractice) : []
  );

  const [country, setCountry] = useState<CountriesItemType[]>([]);
  const [countryDropdownOpen, setCountryDropdownOpen] = useState(false);
  const [activeCountry, setActiveCountry] = useState<string[]>(
    !!trainerSearch?.countryId ? [].concat(trainerSearch?.countryId) : []
  );
  const countryButtonRef = useRef<HTMLButtonElement>(null);
  const countryDropdownRef = useRef<HTMLDivElement>(null);

  const [region, setRegion] = useState<RegionsItemType[] | undefined>([]);
  const [regionDropdownOpen, setRegionDropdownOpen] = useState(false);
  const [activeRegion, setActiveRegion] = useState<string[]>(
    trainerSearch ? (!!trainerSearch?.region ? [].concat(trainerSearch?.region) : ["All Regions"]) : []
  );
  const regionButtonRef = useRef<HTMLButtonElement>(null);
  const regionDropdownRef = useRef<HTMLDivElement>(null);
  /* Pagination Control */
  const [counter, updateCounter] = useState(0);
  const [records, setRecords] = useState(6);
  const [offset, setOffset] = useState(0);
  const [reset, setReset] = useState(false);
  const [skeleton, setSkeleton] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number | null>(null);
  /* Search Button */
  const searchButtonRef = useRef<HTMLButtonElement>(null);
  const [searchClicked, setSearchClicked] = useState(false);
  /* Ajax States */
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  /* Validation Messages */
  const emptyMessage = data?.contentstackAtoListPage?.filterConfiguration?.noResultsMessage;
  /* Results Ref */
  const resultsRef = useRef<HTMLDivElement>(null);

  /* Load Mini Find A Trainer Search */
  useEffect(() => {
    if (trainerSearch === undefined) return;
    if (countryEntries && countryEntries.length > 0) {
      setIsLoading(true);
      setSkeleton(true);
      setSearchClicked(true);
      const fetchPaginatedData = false;
      const miniSearch = true;
      useGtmTrigger({ activeCountry, activeRegion, activeTraining, countryEntries, name });
      useFetchATOData({
        fetchPaginatedData,
        trainerSearch,
        miniSearch,
        setIsLoading,
        setTotalRecords,
        setErrorMessage,
        setOffset,
        setATOEntries,
        emptyMessage,
        records,
        setReset,
        setSkeleton,
      });
    }
  }, [trainerSearch, countryEntries]);

  /* Input Handlers */
  const { handleNameChange } = useInputChanges({ setName });

  /* Dropdown Handlers */
  const { handleCountryDropdown, handleRegionDropdown, handleActiveFilteredItems } = useDropdown({
    activeCountry,
    country,
    data,
    setActiveTraining,
    setTraining,
    setActiveCountry,
    setCountry,
    setCountryDropdownOpen,
    countryEntries,
    countryButtonRef,
    countryDropdownOpen,
    countryDropdownRef,
    setActiveRegion,
    setRegion,
    setRegionDropdownOpen,
    region,
    regionButtonRef,
    regionDropdownOpen,
    regionDropdownRef,
    trainerSearch,
  });

  useEffect(() => {
    useFetchCountriesData({ setCountryEntries });
  }, []);

  /* API Parameters */
  const params = useParams({ activeTraining, activeCountry, activeRegion, name, records, offset });

  /* Filter Validation */
  const { validate } = useValidateFilters({
    name,
    activeTraining,
    activeCountry,
    activeRegion,
    setIsLoading,
    setTotalRecords,
    setATOEntries,
    setErrorMessage,
    emptyMessage,
    setSkeleton,
  });

  /* Search Button Handler */
  const handleSearchButtonClick = () => {
    setSearchClicked(true);
    setSkeleton(true);
  };

  useEffect(() => {
    if (!searchClicked) return;
    if (validate() && reset) {
      useGtmTrigger({ activeCountry, activeRegion, activeTraining, countryEntries, name });
      const fetchPaginatedData = false;
      useFetchATOData({
        params,
        fetchPaginatedData,
        setIsLoading,
        setTotalRecords,
        setErrorMessage,
        setOffset,
        setATOEntries,
        emptyMessage,
        records,
        setReset,
        setSkeleton,
      });
    }
  }, [searchClicked, reset]);

  /* Load More Button Handler */
  const handleLoadMoreClick = () => {
    setSearchClicked(false);
    updateCounter((currCounter) => currCounter + 1);
  };

  /* Load Paginated Results */
  useEffect(() => {
    if (counter === 0) return;
    if (validate()) {
      const fetchPaginatedData = true;
      useFetchATOData({
        params,
        fetchPaginatedData,
        setIsLoading,
        setTotalRecords,
        setErrorMessage,
        setOffset,
        setATOEntries,
        emptyMessage,
        records,
        setReset,
        setSkeleton,
      });
    }
  }, [counter]);

  /* Reset Filter On Enter/Click With Filter Controls */
  useResetFilter({
    setRecords,
    setOffset,
    nameRef,
    searchButtonRef,
    setReset,
  });

  // Focus On Results
  useLayoutEffect(() => {
    if (!isLoading && !searchClicked) return;
    if (isLoading && searchClicked && resultsRef && resultsRef.current) {
      resultsRef.current.scrollIntoView({
        behavior: "smooth",
      });
      setSearchClicked(false);
    }
  }, [isLoading, resultsRef, searchClicked]);

  /* Newsletter Props */
  useNewsletterPopup({
    config: newsletter?.config,
    copy: newsletter?.copy,
    submitted: newsletter?.submitted,
    pageOverride: page?.newsletter?.show_popup_page,
  });

  /* Page Props */
  const ato = data?.contentstackAtoListPage;

  /* Filter Props */
  const filterProps: ATOFilterPreviewProps = {
    activeTraining: activeTraining,
    activeCountry: activeCountry,
    activeRegion: activeRegion,
    country: country,
    countryButtonRef: countryButtonRef,
    countryDropdownOpen: countryDropdownOpen,
    countryDropdownRef: countryDropdownRef,
    handleActiveFilteredItems: handleActiveFilteredItems,
    handleCountryDropdown: handleCountryDropdown,
    handleNameChange: handleNameChange,
    handleRegionDropdown: handleRegionDropdown,
    handleSearchButtonClick: handleSearchButtonClick,
    name: name,
    nameRef: nameRef,
    region: region,
    regionButtonRef: regionButtonRef,
    regionDropdownOpen: regionDropdownOpen,
    regionDropdownRef: regionDropdownRef,
    searchButtonRef: searchButtonRef,
  };

  return (
    <>
      <ATO.Template
        atoEntries={ATOEntries}
        defaultMeta={data.contentstackGlobalMeta}
        defaultValues={defaultValues}
        errorMessage={errorMessage}
        filterProps={filterProps}
        handleLoadMoreClick={handleLoadMoreClick}
        hero_banner={ato?.hero_banner}
        isLoading={isLoading}
        location={location}
        meta={ato?.meta}
        model={model}
        resultsRef={resultsRef}
        schema={schema}
        skeleton={skeleton}
        totalRecords={totalRecords}
        training={training}
      />
    </>
  );
};

export const query = graphql`
  query ($id: String) {
    contentstackNewsletterPopup {
      ...newsletterPopup
    }
    contentstackGlobalMeta {
      ...defaultMeta
    }
    contentstackAtoListPage(id: { eq: $id }) {
      ...metaAtoList
      newsletter {
        show_popup_page
      }
      hero_banner {
        content
        image {
          url
        }
        image_horizontal_position
      }
      filterConfiguration: filter_configuration {
        noResultsMessage: no_results_message
      }
    }
    allContentstackResourceHubBestPractices {
      edges {
        node {
          id
          colour
          title
          ato
        }
      }
    }
  }
`;

export default ATOPage;
