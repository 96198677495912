import { API, api } from "modules/api";
import { OrganizationsItemType, OrganizationsGetResponse } from "modules/api/endpoints/organizations";

type RecordsProps = {
  name: string | null;
  bestPractices: string | null;
  countryId: string | null;
  region: string | null;
  numberPerPage: number;
  offset: number;
};

type useFetchATODataProps = {
  emptyMessage: string;
  fetchPaginatedData: boolean;
  params?: RecordsProps;
  miniSearch?: boolean;
  setIsLoading: (value: React.SetStateAction<boolean>) => void;
  setTotalRecords: (value: React.SetStateAction<number | null>) => void;
  setErrorMessage: (value: React.SetStateAction<string | null>) => void;
  setOffset: (value: React.SetStateAction<number>) => void;
  setATOEntries: (value: React.SetStateAction<OrganizationsItemType[] | null>) => void;
  records: number;
  trainerSearch?: RecordsProps;
  setReset: React.Dispatch<React.SetStateAction<boolean>>;
  setSkeleton: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useFetchATOData = async ({
  emptyMessage,
  fetchPaginatedData,
  params,
  miniSearch,
  setIsLoading,
  setTotalRecords,
  setErrorMessage,
  setOffset,
  setATOEntries,
  records,
  trainerSearch,
  setReset,
  setSkeleton,
}: useFetchATODataProps) => {
  try {
    !fetchPaginatedData && setReset(false);
    const atoResponse = await api(API.GET_ORGANIZATIONS(miniSearch ? trainerSearch : params)).then(
      (res: OrganizationsGetResponse) => res.data
    );

    if (atoResponse.records.length === 0) {
      // No records
      setIsLoading(false);
      setSkeleton(false);
      setTotalRecords(0);
      setErrorMessage(emptyMessage);
      return;
    }
    // Successful
    setIsLoading(false);
    setSkeleton(false);
    setTotalRecords(atoResponse.totalRecords);
    if (fetchPaginatedData) {
      setOffset((currOffset) => currOffset + records);
      setATOEntries((currEntries) => {
        if (currEntries) {
          return [...currEntries, ...atoResponse.records];
        }
        return atoResponse.records;
      });
    } else {
      setOffset(records);
      setATOEntries(atoResponse.records);
    }
    setErrorMessage(null);
  } catch (error) {
    // Error
    setIsLoading(false);
    setSkeleton(false);
    setTotalRecords(0);
    setErrorMessage(`${error}.`);
  }
};
