import React, { useEffect, useState } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";

import { CtaProps } from "components/Cta/types";
import HeroCarouselSkeleton from "./Skeleton";
import { isMobile } from "modules/platform/context";

import "./Slider/styles.scss";

export type HeroCarouselProps = {
  arrow?: boolean;
  hero_carousel_module: {
    hero_carousel_items: {
      hero_carousel_image: {
        url: string;
      };
      cta: CtaProps;
      hero_carousel_subtext: string;
      hero_carousel_title: string;
      image_overlay: boolean;
    };
  }[];
  right_align_button?: boolean;
  slider_speed: number;
};

const Carousel: React.FunctionComponent<HeroCarouselProps> = ({
  arrow,
  hero_carousel_module,
  right_align_button,
  slider_speed,
}) => {
  const heroClassName = clsx("heroCarouselWrapper", right_align_button && "RightAlignButton", arrow && "arrow");

  const [showHeroCarousel, setShowHeroCarousel] = useState(false);
  const HeroCarousel = loadable(() => import("components/Hero/Carousel/Slider"), {
    fallback: (
      <HeroCarouselSkeleton
        hero_carousel_module={hero_carousel_module}
        right_align_button={right_align_button}
        arrow={arrow}
        slider_speed={slider_speed}
      />
    ),
  });

  useEffect(() => {
    if (showHeroCarousel) return;
    document.addEventListener("keydown", () => {
      setShowHeroCarousel(true);
    });
    !isMobile
      ? document.addEventListener("mouseover", () => {
          setShowHeroCarousel(true);
        })
      : document.addEventListener("touchmove", () => {
          setShowHeroCarousel(true);
        });
  }, []);

  return (
    <div className={clsx(heroClassName)}>
      {showHeroCarousel ? (
        <HeroCarousel
          hero_carousel_module={hero_carousel_module}
          right_align_button={right_align_button}
          arrow={arrow}
          slider_speed={slider_speed}
        />
      ) : (
        <HeroCarouselSkeleton
          hero_carousel_module={hero_carousel_module}
          right_align_button={right_align_button}
          arrow={arrow}
          slider_speed={slider_speed}
        />
      )}
    </div>
  );
};

export default React.memo(Carousel);
