import React from "react";

import { OrganizationsItemType } from "modules/api/endpoints/organizations";
import * as ATO from "components/ATO";
import { TrainingType } from "../types";

import * as styles from "./styles.module.scss";

export type ATOGridProps = {
  entries: OrganizationsItemType[] | null;
  totalItems: number | null;
  training: TrainingType[];
};

const Grid: React.FC<ATOGridProps> = ({ entries, training }) => {
  return entries && entries.length > 0 ? (
    <div className={styles.atoGrid}>
      <div className="small container">
        <ul className={styles.list}>
          {entries.map((entry: OrganizationsItemType) => {
            return (
              <li key={entry.uid}>
                <ATO.Entry entry={entry} training={training} />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  ) : null;
};

export default Grid;
