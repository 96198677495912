// extracted by mini-css-extract-plugin
export var heroCategoryBanner = "styles-module--heroCategoryBanner--NowiL";
export var titleContainer = "styles-module--titleContainer--96vCn";
export var companyLogo = "styles-module--companyLogo--o4tHh";
export var categoryContainer = "styles-module--categoryContainer--fq80g";
export var mobile = "styles-module--mobile--ZNjup";
export var desktop = "styles-module--desktop--0V76r";
export var strip = "styles-module--strip--rszGx";
export var image = "styles-module--image--UWGpY";
export var flip = "styles-module--flip--PcHa4";
export var productLogo = "styles-module--productLogo--quwrd";
export var dividerMobile = "styles-module--dividerMobile--TwKJZ";
export var VerticalTop = "styles-module--VerticalTop--0Z+HQ";
export var VerticalCentre = "styles-module--VerticalCentre--PpbnT";
export var VerticalBottom = "styles-module--VerticalBottom--34Ruq";
export var HorizontalLeft = "styles-module--HorizontalLeft--+63tk";
export var HorizontalCentre = "styles-module--HorizontalCentre--rL8No";
export var HorizontalRight = "styles-module--HorizontalRight---japH";
export var noCompanyLogo = "styles-module--noCompanyLogo--yqITV";
export var CaseStudy = "styles-module--CaseStudy--f4qb7";
export var ProductTransformation = "styles-module--ProductTransformation--CkU86";
export var ProductItil = "styles-module--ProductItil--H4REP";
export var ProductProPath = "styles-module--ProductProPath--sUeQ9";
export var ProductMaturity = "styles-module--ProductMaturity--7mXMO";
export var ProductResilia = "styles-module--ProductResilia--rC-KI";
export var Brand = "styles-module--Brand--YGlXt";